:global #youtube-live {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.live,
.upcoming {
  margin: 8px 0 0 0;
  text-transform: uppercase;
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  line-height: 1;

  &,
  &:hover {
    color: white;
  }
}

.live {
  background-color: #cc0000;
}

.upcoming {
  background-color: #313131;
}

.icon {
  margin: 0 8px 0 0;
}
