import * as styles from './youtube.live.module.scss';
import CameraIcon from './camera-video-fill.svg';

(async () => {
  const { React, ReactDOM, axios } = await import('../vendors');

  function YoutubeLive() {
    const [stream, setStream] = React.useState(null);
    const [liveBroadcastContent, setLiveBroadcastContent] = React.useState(false);

    React.useEffect(() => {
      axios.get(`/wp-content/themes/pro-child/api/live.php`).then((response) => {
        const stream = response.data[0] ?? null;
        setStream(stream);
        setLiveBroadcastContent(stream?.snippet?.liveBroadcastContent);
      });
    }, []);

    const isLive = liveBroadcastContent === 'live';
    const isUpcoming = liveBroadcastContent === 'upcoming';

    if (isLive || isUpcoming) {
      const url = `https://www.youtube.com/watch/${stream.id.videoId}`;
      return (
        <a href={url} className={isLive ? styles.live : styles.upcoming} target="_blank">
          <CameraIcon className={styles.icon} />
          Skaties tiešraidi
        </a>
      );
    }
    return <></>;
  }

  const domContainer = document.querySelector('#youtube-live');
  if (domContainer) ReactDOM.render(<YoutubeLive />, domContainer);
})();
